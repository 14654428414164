import React, { Component, useContext, useEffect, useState } from "react"
import { Tooltip as MateriaUiTooltip, makeStyles, MenuItem, Select } from "@material-ui/core"
import { Modal, ModalHeader, ModalBody } from "reactstrap"
import { CommunityProjectContext } from "../context"
import { useUserOrgs } from "../../../../hooks/use-user"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import JoinProjectForm from "../joinProjectForm"
import {
  fetch_delete,
  fetch_get,
  fetch_patch,
  fetch_post,
  fetch_put,
} from "../../../../utils/fetch"
import { BsPeopleFill } from "react-icons/bs"
import * as Sentry from "@sentry/gatsby"
import Layout from "../../../../components/layout/layout"
import Content from "../../../../components/content/content"
import Header from "../../../../components/header/header"
import Breadcrumbs from "../../../../components/breadcrumbs/breadcrumbs"

function CommunityProjectContainer(props) {
  let contextData = useContext(CommunityProjectContext)
  const { path, projectSlug, parentOrganization } = props
  const { isAuthenticated, user } = useAuth0()
  var [project, setProject] = React.useState(null)
  var [loadStatus, setLoadStatus] = useState({
    loading: false,
    error: false,
    errorMessage: null,
  })

  const getProject = () => {
    fetch_get(`projects/public/${projectSlug}?parentOrganization=${parentOrganization}`)
      .then(response => {
        console.log(response)

        setProject(response)
        // contextData.setSuccessData(response.data)
        console.log(response)
        setLoadStatus({
          loading: false,
          error: false,
          errorMessage: null,
        })
      })
      .catch(error => {
        setLoadStatus({
          loading: false,
          error: true,
          errorMessage: `${error}`,
        })
        Sentry.captureException(error)
        console.log(`Error while adding project link to project: ${error}`)
      })
  }

  useEffect(() => {
    if (!isAuthenticated) {
      getProject()
    }
  }, [path, projectSlug, parentOrganization])

  return (
    <React.Fragment>
      <Layout pageTitle="Project Detail">
        <div id="project-tooltip-wrapper">
          {project !== null && (
            <Breadcrumbs
              breadcrumbs={[{ label: "Projects", path: "/projects/" }, { label: project.name }]}
            />
          )}

          <Header contentPage={true} className="contentPage">
            <div>&nbsp;</div>
            <div className="row">
              <div className="col-lg-9">
                <h2>{project !== null && <>{project.name}</>}</h2>
              </div>
            </div>

            {project !== null && (
              <>
                {project.externalContributionAllowed && project.externalContribution && (
                  <JoinProjectForm project={project} />
                )}

                {!(project.externalContributionAllowed && project.externalContribution) && (
                  <>Sorry, this project does not support external contribution</>
                )}
              </>
            )}
          </Header>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default CommunityProjectContainer
