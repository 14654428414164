import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import ErrorPage from "./404"
import { useAuth0 } from "@auth0/auth0-react"
import { Loading } from "../components/loading"
import ProjectDetail from "./projects/detail_project"
// import ProtectedRoute from "../components/protected-route"
import PublicProjects from "./projects/projects"
import CommunityProjectJoin from "../components/project-form/communityProject/joinProject/container"
import { fetch_get } from "../utils/fetch"
import { Error } from "../components/error"

const Secure = () => {
  const { isLoading, error } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      {error && <Error message={error.message} />}
      <Router>
        <PublicProjects path="/projects" />
        <ProjectDetail path="/projects/:parentOrganization/:id" />
        <ProjectDetail path="/projects/:parentOrganization/private/:id" />
        <CommunityProjectJoin path="/projects/:parentOrganization/:projectSlug/contributor/join" />
        {/* <ProtectedRoute
          path="/projects/:parentOrganization/private/:id"
          component={ProjectDetail}
        /> */}
        <ErrorPage default />
      </Router>
    </>
  )
}

export default Secure
