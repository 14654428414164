import React, { Component, useContext } from "react"
import { CommunityProjectContextProvider } from "../context"
import CommunityProjectIndex from "./index"

function CommunityProjectContainer(props) {
  return (
    <React.Fragment>
      <CommunityProjectContextProvider>
        {/* <IndustryIndex /> */}
        <CommunityProjectIndex {...props} />
      </CommunityProjectContextProvider>
    </React.Fragment>
  )
}

export default CommunityProjectContainer
